//
// React.
//
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react';
//
// Material UI.
//
import TablePagination from '@mui/material/TablePagination';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//
// Search.
//
import Search from '../../actions/Search'
import Constants from '../../config/Constants'
import Labels from '../../config/Labels'
import Util from '../../services/Util'
//
// CSS.
//
import './SearchResultsPagination.css'

const SearchResultsPagination = (props) => {
    const search = new Search(props);
    const [isOpen, setIsOpen] = useState(false);
    //
    // handleOpen
    //
    const handleOpen = () => {
        setIsOpen(true);
    };
    //
    // handleClose
    //
    const handleClose = () => {
        setIsOpen(false);
    };
    //
    // Get the results for the configured source.
    //
    const searchParameters = props.searchParameters || {}
    const docsCount = searchParameters[Constants.parameter.docsCount] || Constants.defaultParameterValue[Constants.parameter.docsCount]
    const searchResult = props.searchResult || {}
    const result = searchResult[search.getActiveSource()]?.result || {}
    const totalResults = result.total || 0
    const totalPages = Math.ceil(totalResults / docsCount)
    const actualPage = Math.min(searchParameters[search.getPageParameter()] || 1, totalPages)
    const pageRange = Array.from({ length: totalPages }, (_, index) => index + 1)
    //
    // On loading the component, adjust the CSS style based on the width.
    //
    useEffect(() => {
        //
        // Handle Resolution Change
        //
        const handleResolutionChange = () => {
            if (window.innerWidth <= 425 && actualPage === 1) {
                document.querySelector('.MuiTablePagination-root').classList.add('centered-pagination')
                document.querySelector('.MuiToolbar-root').classList.add('centered-pagination-items')
            }else {
                document.querySelector('.MuiTablePagination-root').classList.remove('centered-pagination')
                document.querySelector('.MuiToolbar-root').classList.remove('centered-pagination-items')
            }
        };
        handleResolutionChange();
        window.addEventListener('resize', handleResolutionChange);
        return () => {
          window.removeEventListener('resize', handleResolutionChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //
    // Call search to update the search results.
    //
    const callSearchToUpdateResult = (page) => {
        //
        // Just setting the window.digitalData.search to avoid trigger a "searchDone"
        //
        window.digitalData.search = {
            searchEvent: 'searchDone',
            searchResultNumber: props.searchResult[Constants.resultSource.lucidworks].result?.total || 0,
            searchResultStatus: 'success',
            searchType: props.searchParameters[Constants.parameter.didYouMean] || props.searchParameters[Constants.parameter.queryText] || '',
            searchSourcePage: '',
        }
        //
        // Go to the next page
        //
        props.setCacheNoResultState('')
        const updatedSearchParameters = {
            ...searchParameters,
            [search.getPageParameter()]: page,
        };
        search.search(updatedSearchParameters);
        //
        // Send signals.
        //
        search.sendSignal(Constants.signal.pagination, updatedSearchParameters, [
            {
                name: 'page',
                values: [ page ],
            }
        ]);
    }
    //
    // handleRowsPerPageChange
    //
    const handleRowsPerPageChange = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        callSearchToUpdateResult(rowsPerPage);
    };
    //
    // Get number of digits in "totalPages"
    //
    const countDigits = (totalPages + 1).toString().length;
    //
    // Value use to increase the size of the "select"
    // based on the number of digits in "totalPages"
    //
    const muiTablePaginationSelectWidth = 65 + Math.max(0, (countDigits - 2) * (countDigits - 2 >= 3 ? 8 : 6));
    //
    // Get current language
    //
    const language = props.searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language];

    return (
        <div className='pagination-container'>
            <style>
                {
                    //
                    // This section is responsible for adding or removing padding based on whether the back arrow
                    // is displayed in the pagination (It is displayed when the "actualPage" is greater than 1)
                    // Also sets the size of the required for the "select".
                    // It corresponds to a hard customization of the Mui library to meet the design requirements.
                    //
                }
                {`
                    .MuiTablePagination-root .MuiToolbar-root {
                        padding-left: ${(actualPage > 1 ) ? 50 : 0}px;
                    }
                    .MuiTablePagination-root .mui-select {
                        width: ${muiTablePaginationSelectWidth}px;
                    }
                    .MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input {
                        min-width: ${muiTablePaginationSelectWidth + 1}px;
                    }
                `}
            </style>
            <TablePagination
                component="div"
                page={actualPage}
                count={totalPages + 1}
                rowsPerPageOptions={
                    pageRange.length === 1
                        ? [...pageRange, { value: -1, label: 'All' }]
                        : pageRange.map(value => ({
                                value,
                                label: new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(value),
                            }))
                }
                rowsPerPage={1}
                disabled={pageRange.length === 1}
                slotProps={{
                    select: {
                        value: actualPage,
                        IconComponent: KeyboardArrowDownIcon,
                        open: isOpen,
                        onOpen: handleOpen,
                        onClose: handleClose,
                        className: `mui-select ${isOpen ? 'open' : ''} ${
                            pageRange.length !== 1 ? 'select-hover-effect' : ''
                        }`,
                    },
                    actions: {
                        previousButton: {
                            style: {
                                display: actualPage === 1 ? "none" : "inline-flex"
                            },
                            className: 'arrow-hover-effect'
                        },
                    }
                }}
                labelRowsPerPage={<strong>{Util.getLabel(Labels.Pagination.Page, language)}</strong>}
                labelDisplayedRows={({count}) => (
                    <span><strong>{Util.getLabel(Labels.Pagination.Of, language)}</strong> {`\u00A0 ${new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(count - 1)}`}</span>
                )}
                onPageChange={(event, page) => {
                    callSearchToUpdateResult(page)
                }}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </div>
    )
}

SearchResultsPagination.propTypes = {
    config: PropTypes.object.isRequired,
    filterResult: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCacheNoResultState: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default SearchResultsPagination