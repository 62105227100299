//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Search.
//
import Constants from '../../config/Constants'
import Labels from '../../config/Labels'
import Search from '../../actions/Search'
//
// Material UI.
//
import { Box } from '@mui/material'
//
// CSS.
//
import './DidYouMean.css'

const DidYouMean = (props) => {
    //
    // Search.
    //
    const search = new Search(props);
    //
    // Identify the active result.
    //
    const searchResult = props.searchResult || {}
    const result = searchResult[search.getActiveSource()]?.result || {}
    const searchResultCount = new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(result.total || 0)
    //
    // Render the did-you-mean component.
    //
    return (props.searchParameters[Constants.parameter.didYouMean]) && (
        <div className='DidYouMean'>
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '64px',
                    height: '64px',
                }}
            ><img src={Constants.images.noresult} alt="noresultIcon"/></Box>
            <div className='TextContainer'>
                { props.searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable ? (
                    <span>
                        {/* {search.getLabel(Labels.DidYouMean.DidYouMean(props.searchParameters.queryText))} */}
                        {search.getLabel(Labels.DidYouMean.ShowingResults(props.searchParameters.queryText))}
                        &nbsp;
                        <span
                            className='Suggestion'
                            key={typeof props.searchParameters.didYouMean}
                            onClick={() => {
                                //
                                // Logic for when the user accepts the didYouMean suggestion
                                //
                                props.searchParameters[Constants.parameter.queryText] = props.searchParameters[Constants.parameter.didYouMean];
                                const updatedSearchParameters = {
                                    ...props.searchParameters,
                                }
                                delete updatedSearchParameters[Constants.parameter.didYouMean]
                                props.setSearchParameters(updatedSearchParameters)
                                search.pushHistory(updatedSearchParameters)
                                //
                                // Tracking the suggestion as a search performed
                                // because it is different from the search that triggered the didYouMean in the first place
                                //
                                // const lucidworksResult = props.searchResult[Constants.resultSource.lucidworks] || {}
                                // if (lucidworksResult.result && (lucidworksResult.result.total === 0)) {
                                //     search.setDigitalData(updatedSearchParameters, lucidworksResult, 'no match')
                                // }else {
                                //     search.setDigitalData(updatedSearchParameters, lucidworksResult, 'success')
                                // }
                            }}
                        >
                            {props.searchParameters[Constants.parameter.didYouMean]}
                        </span>
                        &nbsp;
                        <span className='ResultsSuggestion'>({searchResultCount} {search.getLabel((searchResultCount === 1) ? Labels.DidYouMean.Result : Labels.DidYouMean.Results)})</span>
                        &nbsp;
                        <span className='QuestionMark'>?</span>
                    </span>
                ) : (
                    search.getLabel(Labels.DidYouMean.DidYouMeanNoResult(props.searchParameters.queryText))
                )}
            </div>
        </div>
    )
}

DidYouMean.propTypes = {
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default DidYouMean